<template>
  <!-- 箱子遮罩层 -->
  <van-overlay :lock-scroll="false" @mousewheel.prevent.native="() => {}" :show="XZshow" @click="XZshow = false">
    <div class="popup-wrapper-comp" @click.stop>
      <div class="popup-box">
        <div class="popup-box-main1">
          <div class="close-btn-icon" @click="$emit('close')">
          </div>
          <div v-if="game_odds_list.length" class="popup-box-odd1">
            <!-- <MyOddsList :list="game_odds_list" /> -->
            <div class="odd-box">
              <div class="odds-box">
                <div class="odds">
                  <div class="odd-item" :class="`odd-item${item.level-1}`" v-for="item,index in game_odds_list" :key="index">{{item.odds}}</div>
                </div>
              </div>
            </div>
          </div>
          <div @mousewheel.stop="" class="popup-box-list">
            <div v-for="(item, index) in boxList" :key="index" class="popup-box-item">
              <MySingleBox :item="item" />
            </div>
          </div>
          <!-- <div @click="$emit('close')" class="popup-box-close point">
            <div class="close-btn">关闭1</div>
          </div> -->
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    XZshow: {
      type: Boolean,
      default: false,
    },
    boxList: {
      type: Array,
      default() {
        return [];
      },
    },
    game_odds_list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.popup-wrapper-comp {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-box {
    width: 3.33rem;
    height: 2.86rem;
    margin: 0 auto;
    position: relative;
    background: #000;
    font-size: 0.12rem;

    .popup-box-main1 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url(../../../assets/images/mobile/m-batter11-new.png)
        no-repeat;
      background-size: 100% 100%;
      // padding: 0.46rem 0;
      padding-top: 0.47rem;
      box-sizing: border-box;
      .close-btn-icon {
        width: 0.1rem;
        height: 0.1rem;
        position: absolute;
        top: 0.1rem;
        right: 0.07rem;
        background: url(../../../assets/images/mobile/m-batter11-new-close.png)
          no-repeat;
        background-size: 100% 100%;
      }
      //   .popup-box-title {
      //     position: absolute;
      //     width: 0.7rem;
      //     height: 0.2rem;
      //     left: 0.23rem;
      //     top: 0.22rem;
      //     background: url(../../../assets/images/battle/bg-title.png) no-repeat;
      //     background-size: 100% 100%;
      //   }

      .popup-box-odd1 {
        // padding: 0 1rem;
        display: flex;
        justify-content: flex-end;
        // width: 1.54rem;
        min-width: 0.2rem;
        height: 0.17rem;
        // background: url(../../../assets/images/mobile/m-batter12.png) no-repeat;
        // background-size: 100% 100%;
        // padding: 0 0.14rem;
        position: absolute;
        right: 0.27rem;
        top: 0.21rem;
        .odd-box {
          margin-bottom: 0.12rem;
          .odds-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .odds {
              display: flex;
              align-items: center;
              // padding-right: 0.4rem;
              .odd-item {
                margin-right: 0.24rem;
                font-size: 0.1rem;
                padding-left: 0.06rem;
                width: 0.21rem;
                height: 0.1rem;
                line-height: 0.1rem;
              }
              .odd-item0 {
                background: url(../../../assets/images/battle/odd0.png)
                  no-repeat;
                background-size: 100% 100%;
                color: #ff9902;
              }
              .odd-item1 {
                background: url(../../../assets/images/battle/odd1.png)
                  no-repeat;
                background-size: 100% 100%;
                color: #cc0000;
              }
              .odd-item2 {
                background: url(../../../assets/images/battle/odd2.png)
                  no-repeat;
                background-size: 100% 100%;
                color: #660099;
              }
              .odd-item3 {
                background: url(../../../assets/images/battle/odd3.png)
                  no-repeat;
                background-size: 100% 100%;
                color: #003399;
              }
            }
          }
        }
        // .item {
        //   display: flex;
        //   align-items: center;
        //   margin: 0.1rem;

        //   @for $i from 1 through 5 {
        //     .open-lv#{ $i } {
        //       background: lv-color($i);
        //     }
        //   }

        //   .item-point {
        //     width: 0.3rem;
        //     height: 0.3rem;
        //     margin-right: 0.1rem;
        //   }
        // }
      }

      .popup-box-list {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        max-height: 2.2rem;
        // overflow-y: auto;
        width: 100%;
        overflow-x: auto;
        flex-wrap: wrap;
        // padding: 0 0.66rem;
        // padding-top: 0.68rem;
        padding: 0 0.12rem;
        .popup-box-item {
          margin: 0 0.02rem;
        }
      }

      .popup-box-close {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 0.4rem;
        position: absolute;
        bottom: 0.12rem;
        width: 100%;

        .close-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.7rem;
          height: 0.22rem;
          background: url(../../../assets/images/battle/btn-bg3.png) no-repeat;
          background-size: 100% 100%;
          // font-size: 0.28rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>